.react-calendar {
    font-family: 'PT Sans', sans-serif !important;
}

.react-calendar__navigation__next-button {
    background-color: rgb(255, 255, 255, 100);
    border: 1px solid #163578 !important;
    border-radius: 10px;
}

.react-calendar__navigation__next2-button {
    display: none !important;
}

.react-calendar__navigation__prev-button {
    background-color: rgb(255, 255, 255, 100);
    border: 1px solid #163578 !important;
    border-radius: 10px;
    margin-right: 5% !important;
}

.react-calendar__navigation__prev2-button {
    display: none !important;
}

.react-calendar__navigation__next-button:hover {
    background-color: rgb(255, 255, 255, 100);
    border: 1px solid #163578 !important;
    border-radius: 10px;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: #fff !important;

}

.react-calendar__navigation {
    margin-bottom: 0 !important;
}

.react-calendar__month-view__weekdays__weekday {
    margin-top: 10px;
    padding-top: 10px !important;
    border-top: 1px dashed black !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
    color: #a1a1a2;
    text-decoration: none;
}

.react-calendar__navigation__label__labelText {
    font-weight: 900;
    color: #163578;
}

.react-calendar__month-view__days__day abbr {
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend abbr {
    color: black;
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
    font-weight: normal;
}

.react-calendar__century-view__decades__decade:enabled:hover, .react-calendar__century-view__decades__decade:enabled:focus {
    background-color: white !important;
}

.react-calendar__decade-view__years__year:enabled:hover, .react-calendar__decade-view__years__year:enabled:focus {
    background-color: white !important;
}

.react-calendar__year-view__months__month:enabled:hover, .react-calendar__year-view__months__month:enabled:focus {
    background-color: white !important;
}


.react-calendar__month-view__days__day:enabled:hover, .react-calendar__month-view__days__day:enabled:focus {
    background-color: white !important;
}


.highlight:enabled:hover, .highlight:enabled:active, .highlight:enabled:focus {
    background-color: rgb(255, 184, 0, .3) !important;
    cursor: pointer !important;
}

.react-calendar__tile--active {
    color: black !important;
}

.react-calendar__century-view__decades__decade:disabled {
    display: none;
}

.react-calendar__decade-view__years__year:disabled {
    display: none;
}

.react-calendar__year-view__months__month:disabled {
    display: none;
}

.react-calendar__month-view__days__day:disabled {
    background-color: white !important;
}

.react-calendar__navigation button:disabled {
    opacity: 0;
}

.react-calendar__month-view__days__day:hover {
    cursor: default !important;
}

.highlight {
    border: 1px solid #FFB800 !important;
    border-radius: 10px;
}

.react-calendar__year-view__months__month {
    padding: 1.4em 0.5em!important;
}

.react-calendar__navigation__label__labelText {
    font-size: 1.1em;
}

.activated-list {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    --tw-border-opacity: 1;
    border-color: rgb(255 184 0 / var(--tw-border-opacity));
    border-inline-start-width: 6px;
}