.react-datepicker__input-container {
    --tw-border-opacity: 1;
    border-color: rgb(214 211 209 / var(--tw-border-opacity));
    border-width: 1px;
    border-radius: 0.5rem;
    padding: 2px;
}

.reportDatatable tr:nth-child(even) {
    background-color: #F2F2F2;
}
.reportDatatable tr:nth-child(odd) {
    background-color: #FFF;
}

.finance-table tr:nth-child(even) {
    background-color: #f6f6f6;
}

.react-aborist {
    height: auto!important;
}

.tree-parent div {
    max-height: 100%;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.freq-drop-down-content {
    display: none;
}

.freq-drop-down.freq-dropdown-opened .freq-drop-down-content {
    display: block;
}

table.dataTable {
    margin: 0!important;
}

table.dataTable tbody tr:nth-child(even) > .dtfc-fixed-left {
    background-color: #F2F2F2;
    border: 0 solid #c5c5c5;
    border-right-width: 1px;
}
table.dataTable tbody tr:nth-child(odd) > .dtfc-fixed-left {
    background-color: #FFF;
    border: 0 solid #c5c5c5;
    border-right-width: 1px;
}
#DataTables_Table_0_wrapper {
    visibility: hidden;
}
