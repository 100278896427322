.home-banner-image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255, 0)); /* Adjust the gradient colors and direction as needed */
    opacity: 0.6; /* Adjust the overlay opacity as needed */
    pointer-events: none; /* Allows interactions with the image underneath */
}