
.menu-item {
    position: relative;
}

.menu-item::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: white;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
}

/*.menu-item:hover::after {*/
/*    transform: scaleX(1);*/
/*    transform-origin: left;*/
/*}*/

.active-menu-item::after {
    background-color: #FFB800;
    transform: scaleX(1);
}