.others td {
    padding: 5px;
    word-break: break-all;
    word-wrap: break-word
}

.others th {
    padding: 5px 5px 10px 5px;
}

.others tr:nth-child(even) {
    background-color: #F2F2F2;
}

.tree-menu li {
    padding: 8px 0 8px 10px;
    border-radius: 50px;
}

.tree-menu li::before {
    content: '------';
    color: #c9c9ce;
    position: absolute;
    left: 0;
    font-weight: normal;
}

.active-list {
    font-weight: 700;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(242 242 242 / var(--tw-bg-opacity));
    border-radius: 1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
